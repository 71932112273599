<template>
  <div class="row">
    <div class="card p-3 col-12 col-md-12 col-sm-12">
      <div class="row">
        <div class="col-3 col-md-3 col-sm-3 p-2 mt-1">
          {{ analystProcessData ? 'Analista:' + analystProcessData.nome_analista : " " }}
        </div>
        <div class="col-3 col-md-3 col-sm-3 p-2 mt-1">
            {{ analystProcessData ? 'Setor:' + analystProcessData.setor_analista : " " }}
        </div>
        <div class="col-2 col-md-2 col-sm-2 p-2 mt-1">
          <date-picker v-model="monthYear" :lang="lang" value-type="format" type="month" placeholder="Período"></date-picker>
        </div>
        <div class="col-4 col-md-4 col-sm-4 float-right" v-if="analysts" v-show="OPAnalyst === true">
          <multiselect
            v-model="anaLyst"
            :multiple="false"
            :max="100"
            track-by="idresponsavel"
            label="nome"
            :allow-empty="true"
            placeholder="Selecione um analista"
            :close-on-select="true"
            :clear-on-select="false"
            :options="analysts"
            :show-labels="false">
            <template slot="singleLabel" slot-scope="{ option }">
              <strong>{{ option.nome_analista }}</strong>
            </template>
            <template slot="option" slot-scope="{ option }">
              <strong>{{ option.nome_analista }}</strong>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'UserBar',
  data () {
    return {
      anaLyst: [],
      monthYear: '',
      lang: {
        formatLocale: {
          months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
        }
      }
    }
  },
  components: {
    Multiselect,
    DatePicker
  },
  props: {
    analysts: {
      type: Array
    },
    analystProcessData: {
      type: Array
    },
    period: {
      type: String
    },
    OPAnalyst: {
      type: Boolean
    }
  },
  mounted () {
    let dateObj = new Date()
    let month = dateObj.getUTCMonth() + 1
    let year = dateObj.getUTCFullYear()

    this.monthYear = year + '-' + month
  },
  methods: {
    changeAnalyst (AnaLyst) {
      this.$emit('changeAnalyst', AnaLyst)
    },
    changePeriod (monthYear) {
      this.$emit('changePeriod', monthYear)
    }
  },
  watch: {
    anaLyst: {
      handler (val, oldVal) {
        this.changeAnalyst(val)
      },
      deep: true
    },
    monthYear: {
      handler (val, oldVal) {
        this.changePeriod(val)
      },
      deep: true
    }
  }
}
</script>

<style>

</style>
